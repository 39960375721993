import * as mutation from "./mutation-types";
import { initialState } from "./state";
import VariantTypes from "@/constants/checkout/variant-type";
import _cloneDeep from "lodash-es/cloneDeep";
// import i18n from "@/i18n";

export default {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  [mutation.SET_CHECKOUT_FORM_NAME](state, name) {
    state.checkout.form.name = name;
  },

  [mutation.SET_CHECKOUT_FORM_EMAIL](state, email) {
    state.checkout.form.email = email;
  },

  [mutation.SET_CHECKOUT_FORM_ADDRESS](state, address) {
    state.checkout.form.address = address;
  },

  [mutation.SET_CHECKOUT_FORM_BRANCH](state, branch) {
    state.checkout.form.branch = branch;
  },

  [mutation.SET_CHECKOUT_FORM_COUNTRY](state, country) {
    state.checkout.form.country = country;
  },

  [mutation.SET_CHECKOUT_FORM_PHONE_NUMBER](state, phoneNumber) {
    state.checkout.form.phoneNumber = phoneNumber;
  },

  [mutation.SET_CHECKOUT_FORM_PICKUP](state, pickup) {
    state.checkout.form.pickup = pickup;
  },

  [mutation.SET_CHECKOUT_FORM_DELIVERY](state, delivery) {
    state.checkout.form.delivery = delivery;
  },

  [mutation.SET_CHECKOUT_FORM_CITY](state, city) {
    state.checkout.form.city = city;
  },

  [mutation.SET_CHECKOUT_FORM_STATE](state, stateVal) {
    state.checkout.form.state = stateVal;
  },

  [mutation.SET_CHECKOUT_FORM_ZIP_CODE](state, zipCode) {
    state.checkout.form.zipCode = zipCode;
  },

  [mutation.SET_CHECKOUT_FORM_NOTES](state, notes) {
    state.checkout.form.notes = notes;
  },
  [mutation.SET_CHECKOUT_FORM_GOOGLEMAPS](state, googleMaps) {
    state.checkout.form.googleMaps = googleMaps;
  },
  [mutation.SET_CHECKOUT_HELLO_MESSAGE](state, message) {
    state.checkout.helloMessage = message;
  },

  [mutation.SET_CHECKOUT_MESSAGE_ENDING](state, messageEnding) {
    state.checkout.messageEnding = messageEnding;
  },

  [mutation.SET_CHECKOUT_PHONE_NUMBER](state, phoneNumber) {
    state.checkout.phoneNumber = phoneNumber;
  },

  [mutation.SET_CHECKOUT_PRICE_FIELD](state, priceField) {
    state.checkout.priceField = priceField;
  },

  /* Branches */
  [mutation.SET_CHECKOUT_ADD_BRANCH](state, branch) {
    state.checkout.branches = [...state.checkout.branches, branch];
  },

  [mutation.SET_CHECKOUT_UPDATE_BRANCH](state, branch) {
    state.checkout.branches = state.checkout.branches.map((el, index) => {
      if (index === branch.index) {
        return { name: branch.name };
      }
      return { name: el.name };
    });
  },

  [mutation.SET_CHECKOUT_REMOVE_BRANCH](state, index) {
    state.checkout.branches = state.checkout.branches.filter((_, i) => {
      if (i === index) {
        return false;
      }
      return true;
    });
  },

  [mutation.SET_CHECKOUT_SET_BRANCHES](state, branches) {
    state.checkout.branches = [...branches];
  },

  /* Payment options */
  [mutation.SET_CHECKOUT_FORM_PAYMENT_METHODS](state, paymentMethods) {
    state.checkout.form.paymentMethods = paymentMethods;
  },

  [mutation.SET_CHECKOUT_FORM_PAYMENT_OPTIONS_CASH](state, cash) {
    state.checkout.form.paymentMethods.cash = cash;
  },

  [mutation.SET_CHECKOUT_FORM_PAYMENT_OPTIONS_CARD](state, creditCard) {
    state.checkout.form.paymentMethods.creditCard = creditCard;
  },

  [mutation.SET_CHECKOUT_SET_PAYMENT_OPTIONS](state, paymentOptions) {
    state.checkout.form.paymentMethods.custom = [...paymentOptions];
  },

  [mutation.SET_CREATED_AT](state, createdAt) {
    state.createdAt = { ...createdAt };
  },
  /* Detail config */
  [mutation.SET_DETAIL_CONFIG](state, config) {
    state.detailConfig = { ...config };
  },

  [mutation.SET_DETAIL_CONFIG_CAPTION](state, fieldName) {
    state.detailConfig.captionField = fieldName;
  },

  [mutation.SET_DETAIL_CONFIG_CTA](state, field) {
    state.detailConfig.ctaField.field = { ...field };
  },

  [mutation.SET_DETAIL_CONFIG_CTA_BG_COLOR](state, bgColor) {
    state.detailConfig.ctaField.bgColor = bgColor;
  },

  [mutation.SET_DETAIL_CONFIG_CTA_COLOR](state, color) {
    state.detailConfig.ctaField.color = color;
  },

  [mutation.SET_DETAIL_CONFIG_CTA_DOWNLOAD](state, download) {
    state.detailConfig.ctaField.download = download;
  },

  [mutation.SET_DETAIL_CONFIG_CTA_TEXT](state, text) {
    state.detailConfig.ctaField.text = text;
  },

  [mutation.SET_DETAIL_CONFIG_CTA_TYPE](state, type) {
    state.detailConfig.ctaField.type = type;
  },

  [mutation.SET_DETAIL_CONFIG_DESCRIPTION](state, fieldName) {
    state.detailConfig.descriptionField = fieldName;
  },

  [mutation.SET_DETAIL_CONFIG_CUSTOM_FIELDS](state, fieldName) {
    state.detailConfig.customFields = fieldName;
  },

  [mutation.SET_DETAIL_CONFIG_HIDE](state, hide) {
    state.detailConfig.hide = hide;
  },

  [mutation.SET_DETAIL_CONFIG_IMAGE](state, fieldName) {
    state.detailConfig.imageField = fieldName;
  },

  [mutation.SET_DETAIL_CONFIG_TITLE](state, fieldName) {
    state.detailConfig.titleField = fieldName;
  },

  [mutation.SET_EDIT_MODE](state, editMode) {
    state.editMode = editMode;
  },

  [mutation.SET_FIELDS](state, fields) {
    state.fields = fields;
  },

  [mutation.SET_FAVICON](state, favicon) {
    state.favicon = favicon;
  },

  [mutation.SET_FILTERS](state, filters) {
    state.filters = filters;
  },

  [mutation.SET_GA_ID](state, gaId) {
    state.gaId = gaId;
  },

  [mutation.SET_IS_DEMO](state, demo) {
    state.demo = demo;
  },

  [mutation.SET_IS_PRO](state, isPro) {
    state.isPro = isPro;
  },

  [mutation.SET_INVALID_SPREADSHEET](state, hasError) {
    state.hasErrorSpreadsheet = hasError;
  },
  [mutation.SET_LIST_ITEM_CONFIG](state, config) {
    state.listItemConfig = { ...config };
  },

  [mutation.SET_LIST_ITEM_CONFIG_CAPTION](state, field) {
    state.listItemConfig.captionField = field;
  },

  [mutation.SET_LIST_ITEM_CONFIG_CTA](state, field) {
    state.listItemConfig.ctaField.field = { ...field };
  },

  [mutation.SET_LIST_ITEM_CONFIG_CTA_BG_COLOR](state, bgColor) {
    state.listItemConfig.ctaField.bgColor = bgColor;
  },

  [mutation.SET_LIST_ITEM_CONFIG_CTA_COLOR](state, color) {
    state.listItemConfig.ctaField.color = color;
  },

  [mutation.SET_LIST_ITEM_CONFIG_CTA_TEXT](state, text) {
    state.listItemConfig.ctaField.text = text;
  },

  [mutation.SET_LIST_ITEM_CONFIG_CTA_TYPE](state, type) {
    state.listItemConfig.ctaField.type = type;
  },

  [mutation.SET_LIST_ITEM_CONFIG_CUSTOM_FIELDS](state, fields) {
    state.listItemConfig.customFields = [...fields];
  },

  [mutation.SET_LIST_ITEM_CONFIG_DESCRIPTION](state, field) {
    state.listItemConfig.descriptionField = field;
  },

  [mutation.SET_LIST_ITEM_FILTER_BY](state, field) {
    state.listItemConfig.filterByField = field;
  },

  [mutation.SET_LIST_ITEM_CONFIG_IMAGE](state, fields) {
    state.listItemConfig.imageField = { ...fields };
  },

  [mutation.SET_LIST_ITEM_CONFIG_TITLE](state, field) {
    state.listItemConfig.titleField = field;
  },

  [mutation.SET_LIST_ITEM_TOP_CONTENT](state, content) {
    state.listItemConfig.topContent = content;
  },

  /* Hero section / */

  [mutation.SET_LIST_ITEM_HERO](state, hero) {
    state.listItemConfig.hero = {
      ...state.listItemConfig.hero,
      ...hero,
      subheading: {
        ...state.listItemConfig.hero.subheading,
        ...hero.subheading
      },
      title: {
        ...state.listItemConfig.hero.title,
        ...hero.title
      }
    };
  },

  [mutation.SET_LIST_ITEM_HERO_IMAGE](state, image) {
    state.listItemConfig.hero.image = image;
  },

  [mutation.SET_LIST_ITEM_HERO_SHOW](state, show) {
    state.listItemConfig.hero.show = show;
  },

  [mutation.SET_LIST_ITEM_HERO_SUBHEADING](state, subheading) {
    state.listItemConfig.hero.subheading = {
      ...state.listItemConfig.hero.subheading,
      ...subheading
    };
  },

  [mutation.SET_LIST_ITEM_HERO_SUBHEADING_COLOR](state, color) {
    state.listItemConfig.hero.subheading.color = color;
  },

  [mutation.SET_LIST_ITEM_HERO_SUBHEADING_VALUE](state, value) {
    state.listItemConfig.hero.subheading.value = value;
  },

  [mutation.SET_LIST_ITEM_HERO_TITLE](state, title) {
    state.listItemConfig.hero.title = {
      ...state.listItemConfig.hero.title,
      ...title
    };
  },

  [mutation.SET_LIST_ITEM_HERO_TITLE_COLOR](state, color) {
    state.listItemConfig.hero.title.color = color;
  },

  [mutation.SET_LIST_ITEM_HERO_TITLE_VALUE](state, value) {
    state.listItemConfig.hero.title.value = value;
  },

  [mutation.SET_LOGO](state, base64image) {
    state.logo = base64image;
  },

  /* Variants */
  [mutation.ADD_PRODUCT_OPTION](state) {
    state.productOptions.push({
      name: "Variant name",
      required: false,
      type: VariantTypes.NUM_SINGLE_CHOICE,
      options: []
    });
  },

  [mutation.DELETE_PRODUCT_OPTION](state, index) {
    state.productOptions = state.productOptions.filter((_, i) => {
      if (i === index) {
        return false;
      }
      return true;
    });
  },

  [mutation.SET_PRODUCT_OPTIONS](state, productOptions) {
    state.productOptions = _cloneDeep(productOptions);
  },

  [mutation.UPDATE_PRODUCT_OPTION](state, { index, option }) {
    state.productOptions = state.productOptions.map((el, idx) => {
      if (idx === index) {
        return { ...option };
      }
      return el;
    });
  },

  /* Footer */
  [mutation.SET_FOOTER](state, footer) {
    state.footer = { ...state.footer, ...footer };
  },

  [mutation.SET_FOOTER_SHOW](state, show) {
    state.footer.show = show;
  },

  [mutation.SET_FOOTER_DESCRIPTION](state, description) {
    state.footer.description = description;
  },

  [mutation.SET_FOOTER_CONTENT](state, content) {
    state.footer.content = content;
  },

  [mutation.SET_FOOTER_BACKGROUND_COLOR](state, color) {
    state.footer.backgroundColor = color;
  },

  [mutation.SET_FOOTER_TEXT_COLOR](state, color) {
    state.footer.textColor = color;
  },

  [mutation.SET_FOOTER_SOCIAL_URL](state, { key, url }) {
    state.footer.socials[key].url = url;
  },

  [mutation.SET_FOOTER_SOCIAL_SHOW_ICON](state, { key, show }) {
    state.footer.socials[key].show = show;
  },

  /* SEO settings */
  [mutation.SET_META_DESCRIPTION_HOME](state, homeDescription) {
    state.metaFields.homeDescription = homeDescription;
  },

  [mutation.SET_META_DESCRIPTION_ITEM](state, itemDescription) {
    state.metaFields.itemDescription = itemDescription;
  },

  [mutation.SET_META_TITLE_HOME](state, homeTitle) {
    state.metaFields.homeTitle = homeTitle;
  },

  [mutation.SET_META_TITLE_ITEM](state, itemTitle) {
    state.metaFields.itemTitle = itemTitle;
  },

  [mutation.SET_NETLIFY_ID](state, netlifyId) {
    state.netlifyId = netlifyId;
  },
  [mutation.SET_PAYMENT_CIPHERED_KEYS](state, value) {
    if ("boolean" === typeof value) state.paymentCfg.cipheredKeys = value;
  },
  [mutation.SET_PAYMENT_CHANGED_KEYS](state, value) {
    if ("boolean" === typeof value) state.paymentCfg.changedKeys = value;
  },
  [mutation.SET_PAYMENT_CONFIG_ACTIVE](state, value) {
    state.paymentCfg.active = value;
  },
  [mutation.SET_PAYMENT_CONFIG_PAYMENT_METHOD](state, value) {
    state.paymentCfg.selectedPaymentMethod = value;
  },
  [mutation.SET_PAYMENT_CONFIG_SELECTED_CURRENCY](state, value) {
    state.paymentCfg.selectedCurrency = value;
  },
  [mutation.SET_PAYMENT_CONFIG_TEXT_BUTTON_PAY](state, value) {
    state.paymentCfg.textButtonPay = value;
  },
  [mutation.SET_PAYMENT_CONFIG_STRIPE_PUBLIC_KEY](state, value) {
    state.paymentCfg.stripeCfg.publicKey = value;
  },
  [mutation.SET_PAYMENT_CONFIG_STRIPE_PRIVATE_KEY](state, value) {
    state.paymentCfg.stripeCfg.privateKey = value;
  },
  [mutation.SET_SELECTED_FILTER](state, filter) {
    state.selectedFilter = filter;
  },

  [mutation.SET_SHEET_DATA](state, sheet) {
    state.sheet = sheet;
  },

  [mutation.SET_SHEET_ID](state, sheetId) {
    state.sheetId = sheetId;
  },

  [mutation.SET_SHEET_ITEMS](state, items) {
    state.items = items;
  },

  [mutation.SET_SHEET_NAME](state, sheetName) {
    state.sheetName = sheetName;
  },
  [mutation.SET_BIEW_DOMAIN](state, biewDomain) {
    state.biewDomain = biewDomain;
  },
  [mutation.SET_SITE_DOMAIN](state, domain) {
    state.domain = domain;
  },
  [mutation.SET_CUSTOM_DOMAIN](state, customDomain) {
    state.customDomain = customDomain;
  },
  [mutation.SET_DOMAIN_TYPE](state, domainType) {
    state.domainType = domainType;
  },
  [mutation.SET_SITE_NAME](state, siteName) {
    state.siteName = siteName;
  },

  [mutation.SET_FONTS](state, fonts) {
    state.fonts = fonts;
  },

  [mutation.SET_SITE_SUBDOMAIN](state, subdomain) {
    state.subdomain = subdomain;
  },

  [mutation.SET_WEBSITE_ID](state, websiteId) {
    state.websiteId = websiteId;
  },

  [mutation.SET_FIXED_NAV](state, fixedNav) {
    state.fixedNav = fixedNav;
  },

  /* Theme settings */
  [mutation.SET_THEME](state, theme) {
    state.theme = { ...state.theme, ...theme };
  },

  [mutation.SET_THEME_BACKGROUND_COLOR](state, color) {
    state.theme.backgroundColor = color;
  },

  [mutation.SET_THEME_HEADER_COLOR](state, color) {
    state.theme.navbarColor = color;
  },

  [mutation.SET_THEME_FONT](state, font) {
    state.theme.font = font;
  },

  /* Progressive Web App */
  [mutation.SET_PWA_ACTIVE](state, active) {
    state.pwa.active = active;
  },

  [mutation.SET_PWA_NAME](state, name) {
    state.pwa.name = name;
  },

  [mutation.SET_PWA_COLOR](state, color) {
    state.pwa.color = color;
  },

  [mutation.SET_PWA_ICON](state, icon) {
    state.pwa.icon = icon;
  },

  /* Biew specific settings */
  [mutation.SET_BIEW_CONFIG](state, biewConfig) {
    state.biewConfig = biewConfig;
  },

  /* Version */
  [mutation.SET_VERSION](state, version) {
    state.version = version;
  },

  [mutation.SET_USER_ADMIN](state, user) {
    state.userAdmin = user;
  }
};
