import store from "../store";
import currency from "currency.js";

export default {
  currency(amount) {
    return currency(amount, store.getters["checkout/getMoneyFormat"]);
  },
  format(amount) {
    return currency(amount, store.getters["checkout/getMoneyFormat"]).format();
  },
  parse(amount) {
    return currency(amount, store.getters["checkout/getMoneyFormat"]).value;
  }
};
