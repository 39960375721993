import currency from "currency.js";

export default {
  getTotal(state) {
    let total = currency(0);
    state.items.forEach(item => {
      const subtotal = currency(item.price).multiply(item.amount);
      total = total.add(subtotal);
    });
    return total.value;
  },
  getMoneyFormat(state) {
    const currencyFormat = state?.currency;
    return {
      decimal: currencyFormat?.decimal || ".",
      pattern: currencyFormat?.pattern || "!#",
      precision: currencyFormat?.precision || 0,
      symbol: currencyFormat?.symbol || "",
      separator: currencyFormat?.separator || ","
    };
  }
};
