const ADDRESS = "address";
const BRANCH = "branch";
const CITY = "city";
const COUNTRY = "country";
const DELIVERY_ZONE = "deliveryZone";
const EMAIL = "email";
const NAME = "name";
const NOTES = "notes";
const PAYMENT_METHOD = "paymentMethod";
const PHONE_NUMBER = "phoneNumber";
const RECEIVE_MODE = "receiveMode";
const STATE = "state";
const ZIP_CODE = "zipCode";
const MARKER = "marker";

export default {
  ADDRESS,
  BRANCH,
  CITY,
  COUNTRY,
  DELIVERY_ZONE,
  EMAIL,
  NAME,
  NOTES,
  PAYMENT_METHOD,
  PHONE_NUMBER,
  RECEIVE_MODE,
  STATE,
  ZIP_CODE,
  MARKER
};
