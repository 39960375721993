import axios from "axios";

const model = "sites";

export default {
  create(data) {
    return axios.post(`/api/${model}/create`, data);
  },

  createV2(data) {
    return axios.post(`/api/${model}/v2/create`, data);
  },

  createSubdomain(data) {
    return axios.post(`/api/${model}/create-subdomain`, data);
  },

  createSubdomainV2(data) {
    return axios.post(`/api/${model}/v2/create-subdomain`, data);
  },

  hide(data) {
    return axios.post(`/api/${model}/hide`, data);
  },
  duplicate(data) {
    return axios.post(`/api/${model}/duplicate`, data);
  },
  delete(data) {
    return axios.post(`/api/${model}/delete`, data);
  },

  deleteV2(data) {
    return axios.post(`/api/${model}/v2/delete`, data);
  },
  launch(data) {
    return axios.post(`/api/${model}/launch`, data);
  },

  launchV2(data) {
    return axios.post(`/api/${model}/v2/launch`, data);
  },

  upgrade(data) {
    return axios.post(`/api/${model}/upgrade`, data);
  },

  updateDomain(data) {
    return axios.patch(`/api/${model}/domain`, data);
  },

  updateDomainV2(data) {
    return axios.post(`/api/${model}/v2/domain`, data);
  },

  findUserWebsites(userEmail) {
    return axios.get(`/api/${model}/${userEmail}/sites`);
  },
  findMyWebsites(uid = null) {
    return axios.get(`/api/${model}/${uid}/mySites`);
  },
  reasignSubscription(originWebsiteId, destinationWebsiteId) {
    return axios.put(
      `/api/${model}/${originWebsiteId}/reasign-subscription/${destinationWebsiteId}`
    );
  },
  uptadeStatusWebsite(websiteId) {
    return axios.patch(`/api/sites/${websiteId}/update-availability`);
  }
};
